<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import {
  franchiseSingleListAPI,
  franchiseSingleListSummaryAPI,
  franchiseBillListAPI,
  franchiseBillListSummaryAPI,
  franchiseCashierListAPI,
  franchiseCashierListSummaryAPI,
  franchiseCategoryListAPI,
  franchiseCategoryListSummaryAPI,
  franchiseTimeListAPI,
  franchiseTimeListSummaryAPI,
  franchiseListShopSummaryAPI,
  franchiseListShopSummarySummaryAPI,
  franchiseListMachineSummaryAPI,
  franchiseListMachineSummarySummaryAPI,
  franchiseListDateSummaryAPI,
  franchiseListDateSummarySummaryAPI
} from '@/api/joinClient/report/saleTotal' // 门店销售列表
import TablePage from '@/components/tablePage'
import {
  singleListAPI,
  singleListSummaryAPI,
  billListAPI,
  billListSummaryAPI,
  cashierListAPI,
  cashierListSummaryAPI,
  categoryListAPI,
  categoryListSummaryAPI,
  timeListAPI,
  timeListSummaryAPI,
  listShopSummaryAPI,
  listShopSummarySummaryAPI,
  listMachineSummaryAPI,
  listMachineSummarySummaryAPI,
  listDateSummaryAPI,
  listDateSummarySummaryAPI
} from '@/api/shop/report/saleTotal' // 门店销售列表
export default {
  name: 'SaleTotal',
  components: {
    TablePage
  },
  props: {
    shopType: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '门店销售汇总',
        rowKey: 'shopId',
        search: [
          {
            label: '销售时间',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编号' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'categoryNos', label: '类别编号' },
              { filter: 'categoryNames', label: '类别名称' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'goods' || this.options?.tabColumnType == 'billDateStr',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCategory',
            option: {
              filter: 'categoryIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'category' || this.options?.tabColumnType == 'billDateStr',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType != 'cashier',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCashier',
            option: {
              filter: 'cashierIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'cashier',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择收银员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'cashier' })
                    }
                  }
                ]
              }
            }
          }),
          {
            type: 'input',
            label: '小票查询',
            model: '',
            hide: () => this.options?.tabColumnType == 'note',
            filter: 'billNo',
            seniorSearch: true
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'shopGoods',
        tabsColumns: [
          {
            title: '门店商品汇总查询',
            type: 'shopGoods',
            getSummaryApi: this.shopType == 2 ? franchiseSingleListSummaryAPI : singleListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseSingleListAPI : singleListAPI,
            defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listSingleItemExport' : '/api/system/shop/report/sale/summary/listSingleItemExport',
              exportName: '门店商品汇总查询'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '商品汇总查询',
            type: 'goods',
            getSummaryApi: this.shopType == 2 ? franchiseSingleListSummaryAPI : singleListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseSingleListAPI : singleListAPI,
            defaultBody: { isSummary: true },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listSingleItemExport' : '/api/system/shop/report/sale/summary/listSingleItemExport',
              exportName: '商品汇总查询'
            },
            columns: [
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '门店分类汇总查询',
            type: 'shopCategory',
            getSummaryApi: this.shopType == 2 ? franchiseCategoryListSummaryAPI : categoryListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseCategoryListAPI : categoryListAPI,
            defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listCategorySummaryExport' : '/api/system/shop/report/sale/summary/listCategorySummaryExport',
              exportName: '门店分类汇总查询'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '分类汇总查询',
            type: 'category',
            getSummaryApi: this.shopType == 2 ? franchiseCategoryListSummaryAPI : categoryListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseCategoryListAPI : categoryListAPI,
            defaultBody: { isSummary: true },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listCategorySummaryExport' : '/api/system/shop/report/sale/summary/listCategorySummaryExport',
              exportName: '分类汇总查询'
            },
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '门店汇总查询',
            type: 'shop',
            getSummaryApi: this.shopType == 2 ? franchiseListShopSummarySummaryAPI : listShopSummarySummaryAPI,
            getListApi: this.shopType == 2 ? franchiseListShopSummaryAPI : listShopSummaryAPI,
            // defaultBody: { searchType: 'shop' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listShopSummaryExport' : '/api/system/shop/report/sale/summary/listShopSummaryExport',
              exportName: '门店汇总查询'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '时间段汇总查询',
            type: 'time',
            getSummaryApi: this.shopType == 2 ? franchiseTimeListSummaryAPI : timeListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseTimeListAPI : timeListAPI,
            // defaultBody: { searchType: 'time' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listTimeSlotSummaryExport' : '/api/system/shop/report/sale/summary/listTimeSlotSummaryExport',
              exportName: '时间段汇总查询'
            },
            columns: [
              {
                prop: 'timeSlot',
                label: '时间段',
                minWidth: 100
              },
              {
                prop: 'customerUnitPrice',
                label: '客单价',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'customerUnitQty',
                label: '客单数',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '总优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['customerUnitQty', 'unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '收银员汇总查询',
            type: 'cashier',
            getSummaryApi: this.shopType == 2 ? franchiseCashierListSummaryAPI : cashierListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseCashierListAPI : cashierListAPI,
            // defaultBody: { searchType: 'cashier' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listCashierSummaryExport' : '/api/system/shop/report/sale/summary/listCashierSummaryExport',
              exportName: '收银员汇总查询'
            },
            columns: [
              {
                prop: 'cashierNo',
                label: '收银员编号',
                minWidth: 155
              },
              {
                prop: 'cashierName',
                label: '收银员名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '总优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['customerUnitQty', 'unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '小票汇总查询',
            type: 'note',
            getSummaryApi: this.shopType == 2 ? franchiseBillListSummaryAPI : billListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseBillListAPI : billListAPI,
            // defaultBody: { searchType: 'note' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listBillNoExport' : '/api/system/shop/report/sale/summary/listBillNoExport',
              exportName: '小票汇总查询'
            },
            columns: [
              {
                prop: 'billNo',
                label: '销售小票号',
                type: 'link',
                click: 'update',
                minWidth: 180,
                align: 'center'
              },
              {
                prop: 'billDate',
                label: '销售时间',
                minWidth: 155
              },
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'cashierNo',
                label: '收银员编号',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '收银员名称',
                minWidth: 150
              },
              {
                prop: 'billSaleMoney',
                label: '销售金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'billFactMoney',
                label: '收款金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'payTypes',
                align: 'center',
                label: '收款方式',
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.(x => ({
                    ...x,
                    label: x.value,
                    prop: `payDetailList.${x.payMode}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find(y => y.payModeId === x.payMode)
                        ?.payMoney || 0
                  })) || []
              }
            ],
            summary: ['billSaleMoney', 'billFactMoney']
          },
          {
            title: '收银机汇总查询',
            type: 'machine',
            getSummaryApi: this.shopType == 2 ? franchiseListMachineSummarySummaryAPI : listMachineSummarySummaryAPI,
            getListApi: this.shopType == 2 ? franchiseListMachineSummaryAPI : listMachineSummaryAPI,
            // defaultBody: { searchType: 'machine' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listMachineSummaryExport' : '/api/system/shop/report/sale/summary/listMachineSummaryExport',
              exportName: '收银机汇总查询'
            },
            columns: [
              {
                prop: 'machineNo',
                label: '收银机编号',
                minWidth: 150
              },
              {
                prop: 'machineName',
                label: '收银机名称',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          },
          {
            title: '日期汇总查询',
            type: 'billDateStr',
            getSummaryApi: this.shopType == 2 ? franchiseListDateSummarySummaryAPI : listDateSummarySummaryAPI,
            getListApi: this.shopType == 2 ? franchiseListDateSummaryAPI : listDateSummaryAPI,
            // defaultBody: { searchType: 'billDateStr' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2 ? '/api/system/franchise/shop/report/sale/summary/listDateSummaryExport' : '/api/system/shop/report/sale/summary/listDateSummaryExport',
              exportName: '日期汇总查询'
            },
            columns: [
              {
                prop: 'billDateStr',
                label: '销售日期',
                minWidth: 155
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '销售额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'agioMoney',
                label: '优惠金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'agioMoney']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'update':
          if (this.options.tabColumnType === 'note') {
            this.$router.push({
              name: 'saleTotalDetail',
              query: {
                billNo: row.billNo,
                type: 'Update',
                shopType: this.shopType,
              }
            })
          }
          break
        default:
          break
      }
    }
  }
}
</script>
