<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  saleRankListAPI,
  saleRankListSummaryAPI
} from '@/api/shop/report/saleRank' // 销售列表
export default {
  name: 'SaleRank',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '门店销售排名',
        listNo: true, // 序号
        exportOption: {
          show: true,
          ossKeyUrl: '/api/system/shop/report/sale/ranking/getExportGoodsInfo',
          fastExportUrl: '/api/system/shop/report/sale/ranking/export',
          exportName: '门店销售排名'
        },
        //搜索
        search: [
          {
            label: '销售时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'sales',
        tabsColumns: [
          {
            title: '销量排行',
            type: 'sales',
            getListApi: saleRankListAPI,
            getSummaryApi: saleRankListSummaryAPI,
            defaultBody: { rankingType: 1 },
            exportOption: {
              ossKeyUrl:
                '/api/system/shop/report/sale/ranking/getExportGoodsInfo',
              fastExportUrl: '/api/system/shop/report/sale/ranking/export',
              exportName: '销量排行'
            },
            //表格
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '金额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 120,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain']
          },
          {
            title: '销售额排行',
            type: 'salesMoney',
            getListApi: saleRankListAPI,
            getSummaryApi: saleRankListSummaryAPI,
            defaultBody: { rankingType: 2 },
            exportOption: {
              ossKeyUrl:
                '/api/system/shop/report/sale/ranking/getExportGoodsInfo',
              fastExportUrl: '/api/system/shop/report/sale/ranking/export',
              exportName: '销售额排行'
            },
            //表格
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '金额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 120,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain']
          },
          {
            title: '销售毛利排行',
            type: 'grossProfit',
            getListApi: saleRankListAPI,
            getSummaryApi: saleRankListSummaryAPI,
            defaultBody: { rankingType: 3 },
            exportOption: {
              ossKeyUrl:
                '/api/system/shop/report/sale/ranking/getExportGoodsInfo',
              fastExportUrl: '/api/system/shop/report/sale/ranking/export',
              exportName: '销售毛利排行'
            },
            //表格
            columns: [
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 100,
                align: 'center'
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '金额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 120,
                align: 'center',
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      console.log('sssss', this.options)
      switch (type) {
        default:
          break
      }
    }
  }
}
</script>
