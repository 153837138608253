<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  orderListAPI,
  orderListSummaryAPI,
  getShopPredetermineDateList,
  getShopPredetermineDateListSummary,
  getShopPredetermineShopList,
  getShopPredetermineShopListSummary,
  getShopPredetermineGoodsList,
  getShopPredetermineGoodsListSummary
} from '@/api/shop/report/posReserveQuery' // 销售列表
import { getDicts } from '@/api/system/dict/data'
export default {
  name: 'PosReserveQuery',
  dicts: ['fetch_type', 'order_status'],
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '门店客流量查询',
        rowKey: 'billIda',
        search: [
          {
            label: '预定时间',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'receiverNames', label: '收货人' },
              { filter: 'receiverPhones', label: '收货人电话' }
            ]
          },
          {
            label: '取货日期',
            type: 'datetimerange',
            model: '',
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({
              pickupBeginBillDate: a,
              pickupEndBillDate: b
            })
          },
          {
            type: 'local',
            label: '状态',
            model: '',
            filter: 'orderStatuss',
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              remote: getDicts,
              remoteBody: 'order_status',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'predetermineShopIds',
              label: '预定门店',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择预定门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              label: '取货门店',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择取货门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              label: "收银员",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "summary",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: 'local',
            label: '配送模式',
            model: '',
            filter: 'fetchTypes',
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              remote: getDicts,
              remoteBody: 'fetch_type',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'detail',
        tabsColumns: [
          {
            defaultBody: { searchType: 'detail' },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            title: '预订单明细查询',
            type: 'detail',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport',
              exportName: '预订单明细查询'
            },
            columns: [
              {
                prop: 'billDate',
                label: '预订时间',
                minWidth: 155
              },
              {
                prop: 'billNo',
                label: '预订小票号',
                type: 'link',
                click: 'update',
                minWidth: 180
              },
              {
                prop: 'receiverName',
                label: '收货人/客户名称',
                minWidth: 150
              },
              {
                prop: 'receiverPhone',
                label: '联系电话',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '商品规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 150
              },
              {
                prop: 'unitPrice',
                label: '单价',
                minWidth: 150
              },
              {
                prop: 'saleMoney',
                label: '金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'machining',
                label: '加值属性',
                minWidth: 150
              },
              {
                prop: 'machineNo',
                label: '收银机编号',
                minWidth: 150
              },
              {
                prop: 'machineName',
                label: '收银机',
                minWidth: 150
              },
              {
                prop: 'cashierNo',
                label: '收银员编码',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '收银员',
                minWidth: 150
              },
              {
                prop: 'fetchTypeName',
                label: '配送/取货方式',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '配送/取货门店',
                minWidth: 150
              },
              {
                prop: 'receiverRegionAddress',
                label: '收货地址',
                minWidth: 150
              },
              {
                prop: 'predetermineShopName',
                label: '预定门店',
                minWidth: 150
              },
              {
                prop: 'produceDeptName',
                label: '生产部门',
                minWidth: 150
              },
              {
                prop: 'pickUpBillPosNo',
                label: '取货小票号',
                minWidth: 180
              },
              {
                prop: 'reachDate',
                label: '配送/取货时间',
                minWidth: 200
              },
              {
                prop: 'pickUpDate',
                label: '实际取货时间',
                minWidth: 155
              },
              {
                prop: 'orderStatusName',
                label: '状态',
                minWidth: 120
              },
              {
                prop: 'billRemark',
                label: '备注',
                minWidth: 150
              }
            ],
            summary: ['saleMoney','unitQty']
          },
          {
            defaultBody: { searchType: 'summary' },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            title: '预订单查询',
            type: 'summary',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport',
              exportName: '预订单查询'
            },
            columns: [
              {
                prop: 'billDate',
                label: '预订时间',
                minWidth: 155
              },
              {
                prop: 'billNo',
                label: '预订小票号',
                type: 'link',
                click: 'update',
                minWidth: 180
              },
              {
                prop: 'receiverName',
                label: '收货人/客户名称',
                minWidth: 150
              },
              {
                prop: 'receiverPhone',
                label: '联系电话',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'machineNo',
                label: '收银机编号',
                minWidth: 150
              },
              {
                prop: 'machineName',
                label: '收银机',
                minWidth: 150
              },
              {
                prop: 'cashierNo',
                label: '收银员编码',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '收银员',
                minWidth: 150
              },
              {
                prop: 'fetchTypeName',
                label: '配送/取货方式',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '配送/取货门店',
                minWidth: 150
              },
              {
                prop: 'receiverRegionAddress',
                label: '收货地址',
                minWidth: 150
              },
              {
                prop: 'predetermineShopName',
                label: '预定门店',
                minWidth: 150
              },
              {
                prop: 'produceDeptName',
                label: '生产部门',
                minWidth: 150
              },
              {
                prop: 'pickUpBillPosNo',
                label: '取货小票号',
                minWidth: 180
              },
              {
                prop: 'reachDate',
                label: '配送/取货时间',
                minWidth: 200
              },
              {
                prop: 'pickUpDate',
                label: '实际取货时间',
                minWidth: 155
              },
              {
                prop: 'orderStatusName',
                label: '状态',
                minWidth: 120
              },
              {
                prop: 'billRemark',
                label: '备注',
                minWidth: 150
              }
            ],
            summary: ['saleMoney']
          },
          {
            defaultBody: { searchType: 'payment' },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            title: '预订收款查询',
            type: 'payment',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport',
              exportName: '预订收款查询'
            },
            columns: [
              {
                prop: 'billDate',
                label: '预订时间',
                minWidth: 155
              },
              {
                prop: 'billNo',
                label: '预订小票号',
                type: 'link',
                click: 'update',
                minWidth: 180
              },
              {
                prop: 'receiverName',
                label: '收货人/客户名称',
                minWidth: 150
              },
              {
                prop: 'receiverPhone',
                label: '联系电话',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'machineNo',
                label: '收银机编号',
                minWidth: 150
              },
              {
                prop: 'machineName',
                label: '收银机',
                minWidth: 150
              },
              {
                prop: 'cashierNo',
                label: '收银员编码',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '收银员',
                minWidth: 150
              },
              {
                prop: 'fetchTypeName',
                label: '配送/取货方式',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '配送/取货门店',
                minWidth: 150
              },
              {
                prop: 'receiverRegionAddress',
                label: '收货地址',
                minWidth: 150
              },
              {
                prop: 'predetermineShopName',
                label: '预定门店',
                minWidth: 150
              },
              {
                prop: 'produceDeptName',
                label: '生产部门',
                minWidth: 150
              },
              {
                prop: 'pickUpBillPosNo',
                label: '取货小票号',
                minWidth: 180
              },
              {
                prop: 'reachDate',
                label: '配送/取货时间',
                minWidth: 200
              },
              {
                prop: 'pickUpDate',
                label: '实际取货时间',
                minWidth: 155
              },
              {
                prop: 'payTypes',
                align: 'center',
                label: '收款方式',
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.(x => ({
                    ...x,
                    label: x.payModeName,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find(y => y.payModeId === x.payModeId)
                        ?.payMoney || 0
                  })) || []
              },
              {
                prop: 'orderStatusName',
                label: '状态',
                minWidth: 120
              },
              {
                prop: 'billRemark',
                label: '备注',
                minWidth: 150
              }
            ],
            summary: ['saleMoney']
          },
          {
            defaultBody: { searchType: 'refund' },
            getListApi: orderListAPI,
            getSummaryApi: orderListSummaryAPI,
            title: '预订退款查询',
            type: 'refund',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport',
              exportName: '预订退款查询'
            },
            columns: [
              {
                prop: 'billDate',
                label: '预订时间',
                minWidth: 155
              },
              {
                prop: 'billNo',
                label: '预订小票号',
                type: 'link',
                click: 'update',
                minWidth: 180
              },
              {
                prop: 'receiverName',
                label: '收货人/客户名称',
                minWidth: 150
              },
              {
                prop: 'receiverPhone',
                label: '联系电话',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'machineNo',
                label: '收银机编号',
                minWidth: 150
              },
              {
                prop: 'machineName',
                label: '收银机',
                minWidth: 150
              },
              {
                prop: 'cashierNo',
                label: '收银员编码',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '收银员',
                minWidth: 150
              },
              {
                prop: 'fetchTypeName',
                label: '配送/取货方式',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '配送/取货门店',
                minWidth: 150
              },
              {
                prop: 'receiverRegionAddress',
                label: '收货地址',
                minWidth: 150
              },
              {
                prop: 'predetermineShopName',
                label: '预定门店',
                minWidth: 150
              },
              {
                prop: 'produceDeptName',
                label: '生产部门',
                minWidth: 150
              },
              {
                prop: 'pickUpBillPosNo',
                label: '取货小票号',
                minWidth: 180
              },
              {
                prop: 'reachDate',
                label: '配送/取货时间',
                minWidth: 200
              },
              {
                prop: 'pickUpDate',
                label: '实际取货时间',
                minWidth: 155
              },
              {
                prop: 'payTypes',
                align: 'center',
                label: '退款方式',
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.(x => ({
                    ...x,
                    label: x.payModeName,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find(y => y.payModeId === x.payModeId)
                        ?.payMoney || 0
                  })) || []
              },
              {
                prop: 'orderStatusName',
                label: '状态',
                minWidth: 120
              },
              {
                prop: 'billRemark',
                label: '备注',
                minWidth: 150
              }
            ],
            summary: ['saleMoney']
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPredetermineDateList,
            getSummaryApi: getShopPredetermineDateListSummary,
            title: '预订查询汇总（按日期）',
            type: 'date',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineDateListExport',
              exportName: '预订查询汇总（按日期）'
            },
            columns: [
              {
                prop: 'billDateStr',
                label: '日期',
                minWidth: 155
              },
              {
                prop: 'orderQty',
                label: '数量',
                minWidth: 155,
                sortable: true
              },
              {
                prop: 'orderMoney',
                label: '金额',
                minWidth: 155,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '总优惠金额',
                minWidth: 155,
                sortable: true
              }
            ],
            summary: ['orderQty', 'orderMoney', 'agioMoneySum','unitQty']
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPredetermineShopList,
            getSummaryApi: getShopPredetermineShopListSummary,
            title: '预订查询汇总（按门店）',
            type: 'shop',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineShopListExport',
              exportName: '预订查询汇总（按门店）'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 155
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 155
              },
              {
                prop: 'orderQty',
                label: '数量',
                minWidth: 155,
                sortable: true
              },
              {
                prop: 'orderMoney',
                label: '金额',
                minWidth: 155,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '总优惠金额',
                minWidth: 155,
                sortable: true
              }
            ],
            summary: ['orderQty', 'orderMoney', 'agioMoneySum','unitQty']
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: getShopPredetermineGoodsList,
            getSummaryApi: getShopPredetermineGoodsListSummary,
            title: '预订查询汇总（按商品）',
            type: 'shop',
            exportOption: {
              fastExportUrl:
                '/api/system/shop/base/predetermineOrder/getShopPredetermineGoodsListExport',
              exportName: '预订查询汇总（按商品）'
            },
            columns: [
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 155
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 155
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 155
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 155
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 155
              },
              {
                prop: 'orderQty',
                label: '数量',
                minWidth: 155,
                sortable: true
              },
              {
                prop: 'orderMoney',
                label: '金额',
                minWidth: 155,
                sortable: true
              },
              {
                prop: 'agioMoneySum',
                label: '总优惠金额',
                minWidth: 155,
                sortable: true
              }
            ],
            summary: ['orderQty', 'orderMoney', 'agioMoneySum','unitQty']
          }
        ]
      },
      payTypes: [] //付款方式
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'update':
          this.$router.push({
            name: 'posReserveQueryDetail',
            query: {
              billId: row.billId,
              type: 'Update'
            }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
