<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  materialOrderListAPI,
  shopGoodsListAPI,
  ShopInListAPI,
  ShopOutListAPI,
  shopProduceListAPI,
  shopPurchaseListAPI,
  shopScrapListAPI,
  shopPickingListAPI,
  shopCheckListAPI,
  listShopSaleOrderAPI,
  materialOrderListSummaryAPI,
  shopGoodsListSummaryAPI,
  ShopInListSummaryAPI,
  ShopOutListSummaryAPI,
  shopProduceListSummaryAPI,
  shopPurchaseListSummaryAPI,
  shopScrapListSummaryAPI,
  shopPickingListSummaryAPI,
  shopCheckListSummaryAPI,
  listShopSaleOrderSummaryAPI,
} from "@/api/shop/report/billQuery"; //单据查询
import business from "@/utils/mixin/business"; //业务字典
export default {
  name: "PassengerFlow",
  mixins: [business],
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店单据查询",
        rowKey: "shopId",
        getTabsColumns: ({ tabColumnType } = {}) => ({
          columns: this.detailedSummaryFields(
            tabColumnType,
            this.options.search.find((x) => x.filter === "isSummary")?.model
          ),
        }),
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          {
            type: "radio",
            label: "",
            filter: "isSummary",
            model: false,
            change: (v) => {
              this.options.columns = this.detailedSummaryFields(
                this.options.tabColumnType,
                v
              );
              this.$refs.tablePage.getList();
            },
            option: {
              data: [
                {
                  label: "明细",
                  value: false,
                },
                {
                  label: "汇总",
                  value: true,
                },
              ],
            },
          },
          {
            hide: () =>
              this.options?.tabColumnType == "receipt" ||
              this.options?.tabColumnType == "materialOrder" || this.options?.tabColumnType == "clientSaleOrder",

            label: "到货日期",
            type: "date",
            filter: "arrivalDate",
            model: "",
            seniorSearch: true,
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "receipt" ||
                this.options?.tabColumnType == "materialOrder" ||
                this.options?.tabColumnType == "purchase" || this.options?.tabColumnType == 'scrap',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "purchase",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择供应商",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "supplier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCustomer",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "clientSaleOrder" ||
                this.options?.tabColumnType == "clientSale",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择客户",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "customer" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            label: "报损原因",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "scrap",
            type: "local",
            model: "",
            filter: "scrapTypeIds",
            option: {
              multiple: true,
              data: this.shopScrapTypList,
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
            },
          },
          this.$select({
            key: "listSimpleQuery",
            option: {
              filter: "deptIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "clientSaleOrder" ||
                this.options?.tabColumnType == "clientSale",
              option: {
                multiple: true,
                // buttons: [
                //   {
                //     type: 'more',
                //     option: {
                //       title: '选择部门',
                //       width: 1250,
                //       type: 'TreeAndTable',
                //       formData: this.$dialog({ key: 'supplier' })
                //     }
                //   }
                // ]
              },
            },
          }),
          this.$select({
            key: "listProduceOrder",
            option: {
              filter: "produceOrderIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "receipt",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择班次",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "produceOrder" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "receipt",
        tabsColumns: [
          {
            title: "门店订货查询",
            type: "receipt",
            getSummaryApi: shopGoodsListSummaryAPI,
            getListApi: shopGoodsListAPI,
            // defaultBody: { searchType: 'receipt' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopBillOrderExport",
              exportName: "门店订货查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'produceOrderName',
            //     label: '生产班次',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'orderStoreName',
            //     label: '订货仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'produceDeptName',
            //     label: '生产工厂',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'arrivalDate',
            //     label: '到货日期',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "门店原料订货查询",
            type: "materialOrder",
            getSummaryApi: materialOrderListSummaryAPI,
            getListApi: materialOrderListAPI,
            // defaultBody: { searchType: 'materialOrder' },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/shop/bill/listShopBillMaterialOrderExport",
              exportName: "门店原料订货查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'arrivalDate',
            //     label: '到货日期',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "门店采购查询",
            type: "purchase",
            getSummaryApi: shopPurchaseListSummaryAPI,
            getListApi: shopPurchaseListAPI,
            // defaultBody: { searchType: 'purchase' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopPurchaseExport",
              exportName: "门店采购查询",
            },
            // columns: [
            //   {
            //     prop: "billNo",
            //     label: "单据编号",
            //     type: "link",
            //     click: "update",
            //     minWidth: 150,
            //     align: "center",
            //   },
            //   {
            //     prop: "billDate",
            //     label: "单据日期",
            //     minWidth: 155,
            //   },
            //   {
            //     prop: "shopNo",
            //     label: "门店编号",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "shopName",
            //     label: "门店名称",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "storeName",
            //     label: "入库仓库",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "partnerName",
            //     label: "供应商",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "categoryNo",
            //     label: "类别编号",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "categoryName",
            //     label: "类别名称",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "goodsNo",
            //     label: "商品编码",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "goodsName",
            //     label: "商品名称",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "barcode",
            //     label: "条码",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "goodsSpec",
            //     label: "规格",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "unitName",
            //     label: "单位",
            //     minWidth: 150,
            //   },
            //   {
            //     prop: "unitPrice",
            //     label: "单价",
            //     minWidth: 150,
            //     sortable: true,
            //   },
            //   {
            //     prop: "unitQty",
            //     label: "数量",
            //     minWidth: 150,
            //     sortable: true,
            //   },
            //   {
            //     prop: "unitTaxPrice",
            //     label: "含税单价",
            //     minWidth: 150,
            //     sortable: true,
            //   },
            //   {
            //     prop: "taxRate",
            //     label: "税率",
            //     minWidth: 150,
            //     sortable: true,
            //   },
            //   {
            //     prop: "unitTaxMoney",
            //     label: "税额",
            //     minWidth: 150,
            //     sortable: true,
            //   },
            //   {
            //     prop: "unitPriceTaxMoney",
            //     label: "价税合计",
            //     minWidth: 150,
            //     sortable: true,
            //   },
            // ],
            summary: ["unitQty", "unitTaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "门店销售订单",
            type: "clientSaleOrder",
            getSummaryApi: listShopSaleOrderSummaryAPI,
            getListApi: listShopSaleOrderAPI,
            defaultBody: { billType: 140313 },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopSaleOrderExport",
              exportName: "门店销售订单",
            },
            summary: ["unitQty", "unitTaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "门店销售单",
            type: "clientSale",
            getSummaryApi: listShopSaleOrderSummaryAPI,
            getListApi: listShopSaleOrderAPI,
            defaultBody: { billType: 140312 },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopSaleOrderExport",
              exportName: "门店销售单",
            },
            summary: ["unitQty", "unitTaxMoney", "unitPriceTaxMoney"],
          },
          {
            title: "门店报废查询",
            type: "scrap",
            getSummaryApi: shopScrapListSummaryAPI,
            getListApi: shopScrapListAPI,
            // defaultBody: { searchType: 'scrap' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopScrapExport",
              exportName: "门店报废查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'scrapTypeName',
            //     label: '报损原因',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'storeName',
            //     label: '仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "门店调入单查询",
            type: "shopIn",
            getSummaryApi: ShopInListSummaryAPI,
            getListApi: ShopInListAPI,
            // defaultBody: { searchType: 'shopIn' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopInExport",
              exportName: "门店调入单查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'inStoreName',
            //     label: '调入仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'outStoreName',
            //     label: '调出仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'importUnitQty',
            //     label: '发货数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '收货数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["importUnitQty", "unitQty", "unitMoney"],
          },
          {
            title: "门店调出单查询",
            type: "shopOut",
            getSummaryApi: ShopOutListSummaryAPI,
            getListApi: ShopOutListAPI,
            // defaultBody: { searchType: 'shopOut' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopOutExport",
              exportName: "门店调出单查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'inStoreName',
            //     label: '调入仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'outStoreName',
            //     label: '调出仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'importUnitQty',
            //     label: '订货数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '发货数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["importUnitQty", "unitQty", "unitMoney"],
          },
          {
            title: "门店生产查询",
            type: "produce",
            getSummaryApi: shopProduceListSummaryAPI,
            getListApi: shopProduceListAPI,
            // defaultBody: { searchType: 'produce' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopProduceExport",
              exportName: "门店生产查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'storeName',
            //     label: '仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "门店原料消耗",
            type: "picking",
            getSummaryApi: shopPickingListSummaryAPI,
            getListApi: shopPickingListAPI,
            // defaultBody: { searchType: 'picking' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopPickingExport",
              exportName: "门店原料消耗",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'storeName',
            //     label: '仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitPrice',
            //     label: '单价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitMoney',
            //     label: '金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "门店盘点单查询",
            type: "check",
            getSummaryApi: shopCheckListSummaryAPI,
            getListApi: shopCheckListAPI,
            // defaultBody: { searchType: 'check' },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/shop/bill/listShopCheckExport",
              exportName: "门店盘点单查询",
            },
            // columns: [
            //   {
            //     prop: 'billNo',
            //     label: '单据编号',
            //     type: 'link',
            //     click: 'update',
            //     minWidth: 150,
            //     align: 'center'
            //   },
            //   {
            //     prop: 'billDate',
            //     label: '单据日期',
            //     minWidth: 155
            //   },
            //   {
            //     prop: 'shopNo',
            //     label: '门店编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'shopName',
            //     label: '门店名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'storeName',
            //     label: '盘点仓库',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryNo',
            //     label: '类别编号',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'categoryName',
            //     label: '类别名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsNo',
            //     label: '商品编码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsName',
            //     label: '商品名称',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'barcode',
            //     label: '条码',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'goodsSpec',
            //     label: '规格',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitName',
            //     label: '单位',
            //     minWidth: 150
            //   },
            //   {
            //     prop: 'unitSalePrice',
            //     label: '售价',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitQty',
            //     label: '盘点数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'unitSaleMoney',
            //     label: '盘点金额',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'adjust_qty',
            //     label: '盘盈数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'adjustMoney',
            //     label: '盘盈金额',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'lose_qty',
            //     label: '盘亏数量',
            //     minWidth: 150,
            //     sortable: true
            //   },
            //   {
            //     prop: 'loseMoney',
            //     label: '盘亏金额',
            //     minWidth: 150,
            //     sortable: true
            //   }
            // ],
            summary: [
              "unitQty",
              "unitSaleMoney",
              "adjust_qty",
              "adjustMoney",
              "lose_qty",
              "loseMoney",
              'basUnitAccountQty'
            ],
          },
        ],
      },
      shopScrapTypList: [],
    };
  },
  async created() {
    await this.business("shop_scrap_type");
    this.options.search[10].option.data = this.busiDicts?.shop_scrap_type;
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          const billId = row.billId;
          const activeTab = this.options.tabColumnType;
          let routePath = undefined;
          let routeName = undefined;
          let query = {};
          if (activeTab === "receipt") {
            routePath = "/shop/bill/order/OrderDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "materialOrder") {
            routePath = "/shop/bill/materialOrder/materialOrderDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "purchase") {
            routePath = "/shop/bill/stockIn/StockInDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "clientSaleOrder") {
            // routePath = '/clientSaleOrderDetail/detail'
            routeName = "shopClientSaleOrderDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "clientSale") {
            // routePath = '/shop/bill/clientSale/ClientSaleDetail'
            routeName = "ClientSaleDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "scrap") {
            routePath = "/shop/bill/scrap/ScrapDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "shopIn") {
            routePath = "/shop/bill/moveIn/MoveInDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "shopOut") {
            routePath = "/shop/bill/moveOut/MoveOutDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "produce") {
            routePath = "/shop/bill/produce/ProduceDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "picking") {
            routePath = "/shop/bill/waste/WasteDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          } else if (activeTab === "check") {
            routePath = "/shop/bill/check/CheckDetail";
            query = {
              billId: billId,
              type: "Update",
            };
          }
          if (routePath) {
            this.$router.push({
              path: routePath,
              query: query,
            });
          } else {
            this.$router.push({
              name: routeName,
              query: query,
            });
          }

          break;
        default:
          break;
      }
    },
    detailedSummaryFields(type, isBol) {
      //isBol  汇总true  明细false
      //type    哪个页面
      let table = [];
      switch (type) {
        case "receipt":
          if (isBol) {
            table = [
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "produceOrderName",
                label: "生产班次",
                minWidth: 150,
              },
              {
                prop: "orderStoreName",
                label: "订货仓库",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "arrivalDate",
                label: "到货日期",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "materialOrder":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "arrivalDate",
                label: "到货日期",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "purchase":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxPrice",
                label: "含税单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "入库仓库",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxPrice",
                label: "含税单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "clientSaleOrder":
          if (isBol) {
            table = [
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxPrice",
                label: "含税单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "销售仓库",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "deptName",
                label: "部门",
                minWidth: 150,
              },
              {
                prop: "employeeName",
                label: "业务员",
                minWidth: 150,
              },
              {
                prop: "consigneeName",
                label: "收货人",
                minWidth: 150,
              },
              {
                prop: "consigneeTel",
                label: "电话",
                minWidth: 150,
              },
              {
                prop: "consigneeAddress",
                label: "地址",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxPrice",
                label: "含税单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "clientSale":
          if (isBol) {
            table = [
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxPrice",
                label: "含税单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "销售仓库",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "deptName",
                label: "部门",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxPrice",
                label: "含税单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "scrap":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "scrapTypeName",
                label: "报损原因",
                minWidth: 150,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "shopIn":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "importUnitQty",
                label: "发货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "收货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 150,
              },
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "importUnitQty",
                label: "发货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "收货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "shopOut":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "importUnitQty",
                label: "订货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "发货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "inStoreName",
                label: "调入仓库",
                minWidth: 150,
              },
              {
                prop: "outStoreName",
                label: "调出仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "importUnitQty",
                label: "订货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "发货数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "produce":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "picking":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "check":
          if (isBol) {
            table = [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "basUnitAccountQty",
                label: "账面数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "盘点数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitSaleMoney",
                label: "盘点金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "adjust_qty",
                label: "盘盈数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "adjustMoney",
                label: "盘盈金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lose_qty",
                label: "盘亏数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "loseMoney",
                label: "盘亏金额",
                minWidth: 150,
                sortable: true,
              }
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "盘点仓库",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: 'categoryNo',
                label: '类别编号',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 120,
              },
              {
                prop: "unitSalePrice",
                label: "售价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "basUnitAccountQty",
                label: "账面数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "盘点数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitSaleMoney",
                label: "盘点金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "adjust_qty",
                label: "盘盈数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "adjustMoney",
                label: "盘盈金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "lose_qty",
                label: "盘亏数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "loseMoney",
                label: "盘亏金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
              }
            ];
          }
          break;
        default:
          break;
      }
      console.log("table", table);
      return table;
    },
  },
};
</script>
